import axios from "axios";
import { ShowErrorToast, ShowToast } from "../utils/common";
import { PREFIX } from "../hooks/useLocalStorage";
import { BASPATH } from "../config/appConfig";

const setupAxios = () => {
  axios.interceptors.request.use((request) => {
    const user = localStorage.getItem(PREFIX + "auth");
    const authToken = user && JSON.parse(user).token;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (request && request.data && request.data.values) {
      headers = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      };
    }
    if (authToken) {
      headers.Authorization = "Bearer " + authToken;
    }
    request.headers = headers;

    return request;
  });
  axios.interceptors.response.use(
    (res) => {
      const status = res.data.status;
      if (status && res.data) {
        ShowToast(res.data.message);
      }
      return res;
    },
    (e) => {
      if (e.response && e.response.status === 401) {
        delete axios.defaults.headers.common["Authorization"];
        localStorage.clear();
        console.log(
          "window.location",
          window.location.pathname.includes("talent-bench-edit")
        );
        if (!window.location.pathname.includes("talent-bench-edit")) {
          window.location = BASPATH;
          ShowToast(e.response);
        }
      }

      ShowErrorToast(e.response);
      return e.response;
    }
  );
};

export default setupAxios;

export function AxiosGet(url, data = null) {
  return axios.get(`${process.env.REACT_APP_PUBLIC_API_URL}${url}`, {
    params: data,
  });
}

export function AxiosPost(url, data, headers) {
  return axios.post(
    `${process.env.REACT_APP_PUBLIC_API_URL}${url}`,
    data,
    headers ? headers : {}
  );
}

export const AxiosConfig = (method, url, config, data) => {
  return axios({
    method: method,
    url: `${process.env.REACT_APP_PUBLIC_API_URL}${url}`,
    ...config,
    data,
  });
};

export const AxiosPatch = (url, data) => {
  return axios.patch(`${process.env.REACT_APP_PUBLIC_API_URL}${url}`, data);
};

export const AxiosPut = (url, data) => {
  return axios.put(`${process.env.REACT_APP_PUBLIC_API_URL}${url}`, data);
};

export const AxiosDelete = (url) => {
  return axios.delete(`${process.env.REACT_APP_PUBLIC_API_URL}${url}`);
};
