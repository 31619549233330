import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";

const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export function ThemeProvider({ children }) {
  const [themeData, setThemeData] = useState(() => {
    const storedTheme = localStorage.getItem("theme");
    return storedTheme ? JSON.parse(storedTheme) : "light"; // Default to light
  });

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeData === "dark" ? "dark" : "light",
          primary: {
            main: "#fff", // Change this to your desired primary color
          },
          ...(themeData === "dark"
            ? {
                background: {
                  default: "#212121", // Softer dark background color
                  paper: "#1D1D1D", // Paper color for components like cards
                },
                text: {
                  primary: "#FFFFFF", // Primary text color
                  secondary: "#B0BEC5", // Secondary text color
                },
              }
            : {
                background: {
                  default: "#FFFFFF", // Light background color (set to white)
                  paper: "#F5F5F5", // Paper color for components like cards
                },
                text: {
                  primary: "#000000", // Primary text color
                  secondary: "#555555", // Secondary text color
                },
                secondary: {
                  main: "#10b3ba",
                  contrastText: "#fff",
                },
              }),
        },
        components: {
          MuiCheckbox: {
            styleOverrides: {
              root: {
                "&.Mui-checked": {
                  color: "#3498db", // Change 'yourColor' to your desired color
                },
              },
            },
          },
          // MuiCard: {
          //   defaultProps: {
          //     raised: true, // Set default raised prop to true
          //     variant: "outlined", // Change default variant to outlined
          //   },
          //   styleOverrides: {
          //     root: {
          //       // Example of adding custom styles
          //       backgroundColor: "#f5f5f5",
          //       borderRadius: "16px",
          //       boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          //     },
          //   },
          // },
        },
      }),
    [themeData]
  );

  const toggleTheme = () => {
    setThemeData((prev) => (prev === "dark" ? "light" : "dark"));
  };

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove("light", "dark");

    if (themeData === "system") {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? "dark"
        : "light";
      root.classList.add(systemTheme);
    } else {
      root.classList.add(themeData);
    }
  }, [themeData]);

  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(themeData));
  }, [themeData]);

  const setTheme = (newTheme) => {
    setThemeData(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ themeData, toggleTheme, setTheme, theme }}>
      {" "}
      {/* Provide the theme object */}
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </ThemeContext.Provider>
  );
}
